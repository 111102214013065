<template>
  <div class="tile is-ancestor is-vertical is-12">
    <!-- <div class="tile is-parent is-vertical has-text-centered mr-3 ml-3">
      <router-link to="/Record">
        <article class="tile is-child notification is-primary forecast">
          <b-icon
            class="mt-4"
            icon="chart-line"
            custom-size="mdi-96px"
          ></b-icon>
          <h1 class="has-text-white">Share Value</h1>
        </article>
      </router-link>
    </div> -->
    <div class="tile is-parent is-vertical has-text-centered mr-3 ml-3">
      <router-link to="/allocations">
        <article class="tile is-child notification is-primary mr-3 ml-3">
          <!-- <i class="fas fa-5x fa-chart-bar"></i> -->

          <b-icon
            class="mt-4"
            icon="scale-balance"
            custom-size="mdi-96px"
          ></b-icon>
          <h1 class="has-text-white">Allocations</h1>
        </article>
      </router-link>
    </div>
    <!-- <div class="tile is-parent is-vertical has-text-centered mr-3 ml-3">
      <router-link to="/Events">
        <article class="tile is-child notification">
          <b-icon
            class="mt-4"
            icon="calendar-multiple-check"
            custom-size="mdi-96px"
          ></b-icon>
          <h1 class="has-text-white">Events</h1>
        </article>
      </router-link>
    </div>
    <div class="tile is-parent is-vertical has-text-centered mr-3 ml-3">
      <router-link to="/Profile">
        <article class="tile is-child notification is-primary ">
          <b-icon
            class="mt-4"
            icon="card-account-details-star"
            custom-size="mdi-96px"
          ></b-icon>

          <h1 class="has-text-white">Profile</h1>
        </article>
        </router-link> -->
    <hr />
    <div class="columns is-mobile">
      <div class="column is-half is-offset-one-quarter">
        <b-button @click="hotreload" type="is-info ">Force Reload</b-button>
      </div>
    </div>

    <!-- </div> -->
    <!-- </div> -->
    <!-- </div> -->
  </div>

  <!-- </div> -->
</template>

<script>
import { mapState } from "vuex";
export default {
  created() {
    // var now = Date.now();
    // if (now > this.$store.state.lastreload + 1000 * 60 * 60 * 24) {
    //   console.log("UPDATTTTT");
    // }

    // window.addEventListener("online", () => {
    if (navigator.onLine) {
      var now = Date.now();
      //1000 * 60 * 60 * 24
      if (now > this.$store.state.lastreload + 1000 * 60 * 60 * 24) {
        console.log(now);
        console.log(this.$store.state.lastreload);
        console.log(now - this.$store.state.lastreload + 1000 * 60 * 60 * 24);
        this.$store.commit("setlastreload", now);
        this.warning("foo");
        // setTimeout(() => {

        //   console.log("World!");
        // }, 5000);

        // setTimeout(function() {

        //   this.hotreload();

        // }, 5000);
        // this.$router.go("/");
        // window.location.reload(true);
      }
    } else {
      // console.log("OFFLINE");
    }
    //24 hr 1000*60*60*24
    // Update your UI to reflect that there's no connection.
    // });
    // console.log("created!!!!!!");
  },
  computed: {
    ...mapState(["lastreload"])
  },
  methods: {
    hotreload() {
      this.$router.go("/");
    },
    warning() {
      this.$buefy.snackbar.open({
        message: "Yellow button and positioned on top, click to close",
        type: "is-warning",
        position: "is-bottom",
        actionText: "Check Updates",
        indefinite: true,
        onAction: () => {
          // this.$buefy.toast.open({
          //   message: "Checking updates",
          //   queue: false
          // });
          this.$workbox.messageSW({ type: "SKIP_WAITING" });
        }
      });
    }
  }
};
</script>

<style></style>
