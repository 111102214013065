import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from "buefy";
import VueGoogleCharts from "vue-google-charts";
// import VueChartkick from "vue-chartkick";
// import Chart from "chart.js";

import "./assets/scss/app.scss";
import store from "./store";
import { auth } from "./firebase";
import wb from "./registerServiceWorker";

Vue.use(Buefy);
Vue.use(VueGoogleCharts);
Vue.config.productionTip = false;
Vue.prototype.$workbox = wb;

let app;
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }
  if (user) {
    store.dispatch("fetchUserProfile", user);
    // } else {
    // store.dispatch("fetchTeams");
  }
});
