<template>
  <div id="app">
    <Nav />
    <transition name="fadeit">
      <router-view />
    </transition>
    <div class="update-dialog" v-if="prompt">
      <div class="update-dialog__content">
        A new version is found. Refresh to load it?
      </div>
      <div class="update-dialog__actions">
        <button
          class="update-dialog__button update-dialog__button--confirm"
          @click="update"
        >
          Update
        </button>
        <button
          class="update-dialog__button update-dialog__button--cancel"
          @click="prompt = false"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "./views/Nav.vue";
import { mapState } from "vuex";
export default {
  components: {
    Nav // register component
  },
  methods: {
    async update() {
      this.prompt = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    }
    // warning() {
    //   this.$buefy.snackbar.open({
    //     message: "Yellow button and positioned on top, click to close",
    //     type: "is-warning",
    //     position: "is-top",
    //     actionText: "Retry",
    //     indefinite: true,
    //     onAction: () => {
    //       this.$buefy.toast.open({
    //         message: "Action pressed",
    //         queue: false
    //       });
    //     }
    //   });
    // }
  },
  data() {
    return {
      prompt: false
    };
  },
  computed: {
    ...mapState(["userProfile"]),
    showNav() {
      return Object.keys(this.userProfile).length > 1;
    }
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        console.log("UPDATE READY!");
        this.prompt = false;
      });
    }
    this.$workbox.addEventListener("message", event => {
      if (event.data && event.data.type === "SKIP_WAITING") {
        self.skipWaiting();
      }
    });
  }
};
</script>
<style>
.mdi-96px.mdi-set,
.mdi-96px.mdi:before {
  font-size: 96px;
  height: 96px;
  width: 96px;
}

.fadeit-enter {
  opacity: 0;
  /* transform: translate(100em, 0); */
}
.fadeit-leave-to {
  opacity: 0;
  /* transform: translate(-100em, 0); */
}
.fadeit-leave-active {
  /* transition: 1s; */
  /* transition: all 3s ease-out; */
  transition-duration: 0.1s;
  /* transition-property: height, opacity, transform; */
  /* transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); */

  overflow: hidden;
}
.fadeit-enter-active {
  transition-duration: 0.5s;
  /* transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1); */

  overflow: hidden;
}
</style>
