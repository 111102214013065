<template>
  <b-navbar class="is-light mb-4">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <span></span>
        <img class="mr-2" src="/img/heart2.png" alt="IAAD Logo" />
        <h1 class="has-text-black-bis"><b>IAAD</b></h1>
      </b-navbar-item>
      <!-- <div class="has-text-centered	">
        <h1 class="has-text-black-bis">{{ title }}</h1>
      </div> -->
    </template>
    <template slot="start">
      <!-- <b-navbar-item tag="router-link" :to="{ path: '/' }"> -->
      <b-navbar-item>
        {{ title }}
      </b-navbar-item>
    </template>

    <template slot="end">
      <b-navbar-item tag="div">
        <!-- <b-button class="buttons">
          <a class="button is-primary">
            <b-icon @click="window.location.reload(true)" icon="refresh"
              >></b-icon
            >
            <span>
              <strong>Force Update</strong>
            </span>
          </a>
        </b-button> -->
      </b-navbar-item>
    </template>
  </b-navbar>
</template>
<script>
import { auth } from "../firebase";
export default {
  created() {
    // var logged = auth.currentUser;
    // console.log(auth.currentUser);
  },
  computed: {
    title: function() {
      return (
        this.$route.meta.title +
        (this.$route.params.id ? this.$route.params.id : "")
      );
    }
  },
  methods: {
    logged() {
      return auth.currentUser;
    }
  }
};
</script>
