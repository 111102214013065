import Vue from "vue";
import Vuex from "vuex";
import * as fb from "../firebase";
import router from "../router/index";
import CoinGecko from "coingecko-api";
// import fetch from "node-fetch";

Vue.use(Vuex);
const CoinGeckoClient = new CoinGecko();
export default new Vuex.Store({
  state: {
    userProfile: {},
    allocationPrices: {},
    snowtraceData: {},
    silverData: {}
    // teamList: [],
    // teamNames: [],
    // actionTimes: [],
    // recordedTimes: [],
    // events: [],
    // teamTimes: [],
    // teamStages: [],
    // teamDivisions: [],
    // selectedStage: "",
    // lastreload: ""
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val;
    },
    setallocationPrices(state, val) {
      state.allocationPrices = val;
    },
    setsnowtraceData(state, val) {
      state.snowtraceData = val;
    },
    setsilverData(state, val) {
      state.silverData = val;
    }
  },
  actions: {
    async login({ dispatch }, form) {
      // sign user in
      const { user } = await fb.auth.signInWithEmailAndPassword(
        form.email,
        form.password
      );

      // fetch user profile and set in state
      dispatch("fetchUserProfile", user);
    },
    async signup({ dispatch }, form) {
      // sign user up
      const { user } = await fb.auth.createUserWithEmailAndPassword(
        form.email,
        form.password
      );

      // create user object in userCollections
      fb.usersCollection.doc(user.uid).set({
        name: form.name
      });

      // fetch user profile and set in state
      dispatch("fetchUserProfile", user);
    },
    async getAllocationPrices({ commit }) {
      // fetch user profile

      let allocations = await CoinGeckoClient.simple.price({
        ids: [
          "bitcoin",
          "ripple",
          "stellar",
          "wonderland",
          "wrapped-staked-olympus"
        ],
        vs_currencies: ["usd"]
      });
      // console.log(allocations.data);
      const allocationPrices = allocations.data;
      commit("setallocationPrices", allocationPrices);

      // set user profile in state
      // commit("setUserProfile", userProfile.data());

      // change route to dashboard
      if (router.currentRoute.path === "/login") {
        router.push("/");
      }
    },
    async getSnowData({ commit }) {
      // var snowQuantity = null;
      const memo_url =
        "https://api.snowtrace.io/api?module=account&action=tokenbalance&contractaddress=0x136acd46c134e8269052c62a67042d6bdedde3c9&address=0xEC24553F112FCB16ED9A83A8f4285701655d9f34&tag=latest&apikey=12ZXURBECG3GBXDWX2S3QWVXTW1C9HPZSY";

      let snowData = await fetch(memo_url);
      var results = await snowData.json();
      // console.log(results["result"]);
      commit("setsnowtraceData", results);
    },
    async getSilverData({ commit }) {
      const silver_url = "https://api.metals.live/v1/spot";

      let silverData = await fetch(silver_url);
      var results = await silverData.json();
      // console.log(results["result"]);
      commit("setsilverData", results);
    },
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const userProfile = await fb.usersCollection.doc(user.uid).get();

      // set user profile in state
      commit("setUserProfile", userProfile.data());

      // change route to dashboard
      if (router.currentRoute.path === "/login") {
        router.push("/");
      }
    },

    async updateProfile({ dispatch }, user) {
      //todo move uidto a state
      const userId = fb.auth.currentUser.uid;
      // update user object
      const userRef = await fb.usersCollection.doc(userId).update({
        name: user.name,
        title: user.title
      });
      console.log(userRef);

      dispatch("fetchUserProfile", { uid: userId });

      // // update all posts by user
      // const postDocs = await fb.postsCollection.where('userId', '==', userId).get()
      // postDocs.forEach(doc => {
      //   fb.postsCollection.doc(doc.id).update({
      //     userName: user.name
      //   })
      // })

      // update all comments by user
      // const commentDocs = await fb.commentsCollection.where('userId', '==', userId).get()
      // commentDocs.forEach(doc => {
      //   fb.commentsCollection.doc(doc.id).update({
      //     userName: user.name
      //   })
      // })
    }
    // async sendactionTimes({ dispatch }, times) {
    //   const userId = fb.auth.currentUser.uid;
    // }
  }
});
