import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import { auth } from "../firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: ""
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Profile.vue"),
    meta: {
      requiresAuth: true,
      title: "Profile"
    }
  },
  {
    path: "/allocations",
    name: "Allocations",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Allocations.vue"),
    meta: {
      requiresAuth: true,
      title: "Allocations"
    }
  },
  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Admin.vue"),
    meta: {
      requiresAuth: true,
      title: "Admin"
    }
  },
  {
    path: "/record",
    name: "Record",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Record.vue"),
    meta: {
      requiresAuth: true,
      title: "Record"
    }
  },
  {
    path: "/events",
    name: "Events",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Events.vue"),
    meta: {
      requiresAuth: true,
      title: "Events"
    }
  },
  {
    path: "/chart",
    name: "Chart",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Chart.vue"),
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  if (requiresAuth && !auth.currentUser) {
    next("/login");
  } else {
    next();
    document.title = to.meta.title || "IAAD";
  }
});

export default router;
