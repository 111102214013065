import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// firebase init
const firebaseConfig = {
  apiKey: "AIzaSyAnqL9K1vJV62SL0eEt6EAY04Mx6EiYC3Q",
  authDomain: "iaadapp-2a67c.firebaseapp.com",
  projectId: "iaadapp-2a67c",
  storageBucket: "iaadapp-2a67c.appspot.com",
  messagingSenderId: "735899075165",
  appId: "1:735899075165:web:22b3b53b3e71a5e8ee83b4"
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const auth = firebase.auth();

// collection references
const usersCollection = db.collection("users");
// const teamsCollection = db.collection("teams");

// export utils/refs
export {
  db,
  auth,
  usersCollection
  // teamsCollection
};
